import { KarrotLocalCountryCode } from "@karrotmarket-com/core";

export const NA_COUNTRY_CODES: readonly KarrotLocalCountryCode[] = [
  KarrotLocalCountryCode.CA,
  KarrotLocalCountryCode.US,
  KarrotLocalCountryCode.UK,
];

export const shouldUseH3LocationName = (
  countryCode: KarrotLocalCountryCode,
) => {
  return NA_COUNTRY_CODES.includes(countryCode);
};
